import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { Icon } from '../../../common/icon'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage1() {
  const question = {
    title: 'Frage 1 von 35',
    headline: 'Geschlecht der Patienten',
    subline: 'Ich arbeite am liebsten...',
  }

  const answers = [
    {
      name: 'Geschlecht',
      id: 'Patienten',
      value: '... mit Patienten',
      desc: '',
    },
    {
      name: 'Geschlecht',
      id: 'Patientinnen',
      value: '... mit Patientinnen',
      desc: '',
    },
    {
      name: 'Geschlecht',
      id: 'Gemischt',
      value: '... in gemischten Bereichen',
      desc: '',
    },
    {
      name: 'Geschlecht',
      id: 'Egal',
      value: 'Das ist mir egal',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="yellow"
              index={1}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-2`}
          prev={`${AREA_CARE_PATH}/spielen`}
          index={1}
        />

        <Icon position="2" name="gender" />
      </main>
    </>
  )
}
